exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-eames-shell-chairs-tsx": () => import("./../../../src/pages/eames-shell-chairs.tsx" /* webpackChunkName: "component---src-pages-eames-shell-chairs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-qanda-tsx": () => import("./../../../src/pages/qanda.tsx" /* webpackChunkName: "component---src-pages-qanda-tsx" */),
  "component---src-pages-quote-index-tsx": () => import("./../../../src/pages/quote/index.tsx" /* webpackChunkName: "component---src-pages-quote-index-tsx" */),
  "component---src-pages-quote-thanks-tsx": () => import("./../../../src/pages/quote/thanks.tsx" /* webpackChunkName: "component---src-pages-quote-thanks-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-templates-case-index-tsx": () => import("./../../../src/templates/CaseIndex.tsx" /* webpackChunkName: "component---src-templates-case-index-tsx" */),
  "component---src-templates-case-post-tsx": () => import("./../../../src/templates/CasePost.tsx" /* webpackChunkName: "component---src-templates-case-post-tsx" */),
  "component---src-templates-note-post-tsx": () => import("./../../../src/templates/NotePost.tsx" /* webpackChunkName: "component---src-templates-note-post-tsx" */)
}

